import React from 'react'
import { useStaticQuery, graphql } from "gatsby";


export const GetURL = (id) => {
  const data = useStaticQuery(graphql`
    query {
	glstrapi {
		 allMenus {
		   URL
		   Label
		   Template
		   Main_Parent {
		     Label
		     URL
		     Alias
		   }
		   Alias
		   Sub_Parent {
		     Alias
		     Label
		     URL
		   }
		   Article {
		     _id
		     Pagename
		   }
		   id
		   Secondary_URL
		 }
	}
    }
  `);

	let PageURL = data.glstrapi.allMenus.filter(item => item.id === id).pop();


 	let URL = PageURL.Secondary_URL ? PageURL.Secondary_URL : PageURL.URL

  	if((PageURL.Main_Parent != null) &&(PageURL.Sub_Parent == null)) {
    	URL = PageURL.Main_Parent.URL + '/' +URL
  	}

  	else if((PageURL.Main_Parent != null) &&(PageURL.Sub_Parent != null)) {
    	URL = PageURL.Main_Parent.URL + '/' + PageURL.Sub_Parent.URL + '/' +URL
  	}

	  return URL.replace(/\/$/, '')+'/';
}


export const NewsDate = (date) => {

	const d  = new Date(date);
	const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
	const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
	const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
	return (
		<span>{`${da} ${mo} ${ye}`}</span>
	)
}