import React from 'react';
import HTMLReactParser from 'html-react-parser';
import { Container, Row, Col } from 'react-bootstrap';
import { GetURL } from "../common/site/functions";
import ModalTiny from "react-bootstrap/Modal"
import { CloseIcon } from "../Icons/Icons";
import { Link } from "@StarberryUtils";
import Loadable from "@loadable/component"
import "./PageHeader.scss";

const OfficeBookAView = Loadable(() => import("../forms/office-details-form"))

const PageHeader = (props) => {

  const [modalBookViewformOpen, setBookViewingformOpen] = React.useState(false);
  const openBookViewformModal = () => {
    setBookViewingformOpen(true);
  }
  const closeBookViewformModal = () => {
    setBookViewingformOpen(false);
  }

  return (
    <section className="page-header sec-pdy-80">
      <Container>
         <Row>
            <Col xl={8}>      
              {props?.title && <h1>{props?.title}</h1> }
              {props?.content && <div className="content">
                {HTMLReactParser(props?.content.replace(/<br\s*\/?>/gi, ''))}
              </div>}
              {(props.cta_1 && props.cta_1_link) || (props.cta_2 && props.cta_2_link) ? (
                <div className={`${props?.mobile_sticky ? 'd-none d-md-flex' : 'd-flex'} btn-wrapper`}>
                   {props.cta_1 == "Get in touch" && props.cta_1_link && (
                   <button className="btn btn-primary contact-book-btn" onClick={openBookViewformModal}>{props.cta_1}</button>
                  )}
                  {props.cta_1 != "Get in touch" && props.cta_1_link && (
                    <Link to={`/${GetURL(props.cta_1_link.id)}`} className="btn btn-primary">
                      {props.cta_1}
                    </Link>
                  )}
                  {props.cta_2 && props.cta_2_link && (
                    <Link to={`/${GetURL(props.cta_2_link.id)}`} className="btn btn-secondary">
                      {props.cta_2}
                    </Link>
                  )}
                </div>
              ) : null}
            </Col>
         </Row>
      </Container>
      <ModalTiny show={modalBookViewformOpen} onHide={closeBookViewformModal} className="modal-form-wrapper office-details-form-model">
        <ModalTiny.Header>
          <div class="modal-close-btn" onClick={closeBookViewformModal}><CloseIcon /></div> 
          <ModalTiny.Title>
            <h4>Get in touch</h4>
            <p className="text-sm">The Dacha team is ready to help. Use the form to get in touch with our property experts.</p> 
          </ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body>
          <OfficeBookAView
            property_img={``}
            property_id={''}
            page_url={null}
            property_title={''}
            negotiator={''} /></ModalTiny.Body>

      </ModalTiny> 
    </section>
  );
};

export default PageHeader;
