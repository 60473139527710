import React, { useRef, useState, useEffect } from "react"
import { Form, Button } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import FileField from './elements/file'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import bsCustomFileInput from 'bs-custom-file-input'
import $ from 'jquery'

import "./assets/styles/Form.scss";

import axios from "axios"
import * as qs from "query-string"

function Register(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");
  const [file, setFile] = useState(""); // storing the uploaded file
  // storing the recived file from backend
  const [data, getFile] = useState({ name: "", path: "" });
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();
  const fields = ([
    {
      element: "config",
      formname: "Register",
      error_text: "Highlighted fields are required | invalid",
      success_text: "Thank you for your interest. A member of our team will contact you shortly.",
      email_temp_user: "register_user",
      email_temp_admin: "register_admin",
      email_server_func: "register",
      event_tracking: "register",
      page_url: "/register"
    },
    {
      grpmd: "12",
      label: "Name*",
      placeholder: "Your Name *",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      labelClass: "text-xs-bold"
    },
    {
      grpmd: "12",
      label: "Email Address*",
      placeholder: "Your Email Address *",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      class: "",
      labelClass: "text-xs-bold"
    },
    {
      grpmd: "12",
      label: "Phone Number*",
      placeholder: "Your Phone Number *",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*$",
      fieldClass: "",
      labelClass: "text-xs-bold"
    },
    {
      grpmd: "12",
      label: "Message",
      placeholder: "Leave your message",
      name: "message",
      element: "textarea",
      class: "message-text-area",
      rows: "3",
      labelClass: "text-xs-bold"
    },
    {
      grpmd: "12",
      name: "Submit",
      type: "submit",
      element: "button",
      value: "submit",
      class: "btn-primary",
      step: "col-md-4 submit-btn-wrap",
      formclass: "banner_submit_btn form-submit-btn"
    },
    {
      text: 'By clicking Send Enquiry, you agree to our <a class="content-link text-xxs">Terms & Conditions</a> and <a href="/privacy-policy/" class="content-link text-xxs">Privacy Policy</a>',
      element: "html",
      class: "frmbtmtext text-xxs col-12"
    },
    {
      element: "captcha",
      class: "captcha-class",
      captchaRef: recaptchaRef
    },
  ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  const handleAttachment = event => {
    const fileItem = event.target.files[0]; // accesing file

    var d = document.getElementById('custom-file');
    //alert(d.value);
    $('.form-control-browsefile .custom-file-label').html(d.value.replace(/C:\\fakepath\\/, ''));

    setFile(fileItem); // storing file 
  };


  useEffect(() => {

    bsCustomFileInput.init()
    if (token !== '') {

      const processFromData = async () => {
        formvalues['g-recaptcha-response'] = token;

        let formData = new FormData();
        if (formvalues?.files) {
          formData.append('files.attachment', formvalues.files, formvalues.files.name); // appending file
        }

        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(async apiRes => {

          if (apiRes?.attachment?.url) {
            // If image from externall url, use that
            if (apiRes.attachment.url.match(/(http|https):\/\//g)) {
              formvalues['file'] = `${apiRes.attachment.url}`;
              formvalues['cvname'] = `${apiRes.attachment.url}`;
            } else { // or we consider it is loading from api url
              formvalues['file'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment.url}`;
              formvalues['cvname'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment.url}`;
            }
          }
          window.grecaptcha.reset()




          // lets send mail
          formvalues['email_subject_user'] = 'Register your interest';
          formvalues['email_subject_admin'] = 'Register your interest';
          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })
        });


        const axiosOptions_netlify = {
          url: fields[0].page_url,
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: qs.stringify(formvalues),
        }

        // axios(axiosOptions_netlify)
        //   .then(response => {
        //     console.log('data stored')
        //   })
        //   .catch(err =>
        //     console.log(err)
        //   );
        // const url = typeof window !== 'undefined' ? window.location.href : ''
        // if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
        //   fields[0].formname = 'Get mortgage help';
        // }
        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-' + fields[0].event_tracking,
          'formId': 'form-' + fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': fields[0].formname
        });

        setShowerror(false);
        setThankyou(true);
        setTimeout(() => {
          $(".alert-success").show().delay(4000).fadeOut();
        }, 500)
        //myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        $('html, body').animate({
          scrollTop: $("#error-target").offset().top - 200
        }, 300);

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    if ((file == '') || (file == null)) {
      $('.form-control-browsefile').addClass('fileerror');
    } else {
      $('.form-control-browsefile').removeClass('fileerror');
    }

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      // myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      $('html, body').animate({
        scrollTop: $("#error-target").offset().top - 200
      }, 300);
    }
    else {
      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;


      json['files'] = file;
      setFile(file);
      setFormvalues(json);
      //setToken("test");
      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();

    }
  };

  return (
    <div className={props.classone ? props.classone : ''}>
      <div ref={myRef} id="error-target" ></div>

      {showerror && <div className="alert-error">
        <p>{fields[0].error_text}</p>
      </div>}

      {showthankyou && <div className="alert-success">
        <p>{fields[0].success_text}</p>
      </div>}

      <div className="row">
      <Form className="form-wrap form-register-interest" name={fields[0].formname} method="post" noValidate validated={validated} onSubmit={handleSubmit} >
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="job_role" value={props.title} />
        <input type="hidden" name="bot-field" />

        {fields.map((field, index) => {
          if ("input" === field.element) {
            return (
              <InputField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                type={field.type}
                fieldClass={field.class}
                placeholder={field.placeholder}
                label={field.label}
                labelClass={field.labelClass}
                required={field.required}
                key={`${field.element}~${index}`}
                pattern={field.patternchk}
                handlechange={handlechange}
              />
            );
          }
          if ("file" === field.element) {
            return (
              <FileField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                label={field.label}
                type={field.type}
                fieldClass={field.fieldClass}
                labelClass={field.labelClass}
                placeholder={field.placeholder}
                key={`${field.element}~${index}`}
                accept={field.accept}
                handlechange={handleAttachment}
                required={field.required}
              />
            );
          }
          if ("select" === field.element) {
            return (
              <SelectField
                name={field.name}
                id={field.id}
                grpmd={field.grpmd}
                label={field.label}
                ref={field.ref}
                required={field.required}
                fieldClass={field.class}
                placeholder={field.placeholder}
                values={field.values}
                key={`${field.element}~${index}`}
                handlechange={handlechange}
                componentprops={props}
              />
            );
          }
          if ("textarea" === field.element) {
            return (
              <TextAreaField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                rows={field.rows}
                fieldClass={field.class}
                label={field.label}
                labelClass={field.labelClass}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.element}~${index}`}
                handlechange={handlechange}
              />
            );
          }
          if ("checkbox" === field.element) {
            return (
              <CheckboxField
                name={field.name}
                ref={field.ref}
                value={field.value}
                fieldClass={field.class}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.name}~${index}`}
                handlechange={handlechange}
              />
            );
          }
          if ("html" === field.element) {
            return (
              <HtmlBox
                text={field.text}
                fieldClass={field.class}
                key={`${field.element}~${index}`}
              />
            );
          }
          if ("captcha" === field.element) {
            return (
              <ReCaptchaBox
                fieldClass={field.class}
                captRef={field.captchaRef}
                key={`${field.element}~${index}`}
                handleonVerify={handleonVerify}
              />
            );
          }
          if ("button" === field.element) {
            return (
              <ButtonField
                name={field.name}
                fieldClass={field.class}
                step={field.step}
                formclass={field.formclass}
                type={field.type}
                value={field.value}
                key={`${field.element}~${index}`}
              />
            );
          }
        })
        }
      </Form>
      </div>
    </div>
  );
}


const RegisterFormPage = (props) => (
  <Register classone={props.classone} title={props.title} />
)

export default RegisterFormPage