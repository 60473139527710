import React from "react"
import { Link } from "@StarberryUtils";

import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import StickyFooter from "../../../components/Footer/StickyFooter";
import NewsLetter from "../../../components/Home/NewsLetter/NewsLetter";
import Breadcrumb from "../../../components/Home/Breadcrumb/Breadcrumb";
import Results from "../../../templates/search-results-template";
import PropertyFooterNew from "../../../components/Footer/propertyFooterNew.js";
import {
  parseSearchUrl
} from "../../../components/SearchResult/utills";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/scss/bootstrap.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../scss/bootstrap-overrides.scss";
import "../../../scss/grunticon.scss";
import "../../../scss/global.scss";
import "../../../scss/custom.scss";

const ResidentialSales = (props) => {

	const [state, setState] = React.useState({
        showMenu: false
    })
    
    const handlerClick = () => {
        setState({ ...state, showMenu: !state.showMenu })
    }

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    const searchParams = parseSearchUrl("lettings", fullpath);
   

  return (
      	<div className={`${state.showMenu ? "open-search-block" : ""} search-results-listings-wrap`}>
            <Header showMenu={state.showMenu} handlerClick={handlerClick} fixed={true} />
            <Breadcrumb data={''} name={`Properties For sale`} tag='detail-page' />
            <Results location={location} locationname="/properties/for-sale/" pcategorytype="residential" fullpathone={fullpathone} fullpathname={fullpath} ptype="sales" ptypetag="for-sale"/>
            <PropertyFooterNew Action={'for-sale'} Area={searchParams.areas} Property_Type={searchParams.type}/>
            {/* <Breadcrumb data={''} name={`Properties For sale`} tag='detail-page' />  */}
            {/* <NewsLetter />  */}
            <Footer popularSearch={"Popular_Search_Sale"} /> 
            {/* <StickyFooter /> */} 
		</div>
  )
}

export default ResidentialSales