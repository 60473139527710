import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Nav, Navbar } from "react-bootstrap";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import useDeviceMedia from "../../utils/useDeviceMedia";
import useHasScrolled from "../../utils/useHasScrolled";
import MenuList from "./MenuList";
import { useLocation } from "@reach/router";
import LogoWhite from "../../../images/logo.svg";
import LogoBlue from "../../../images/logo-blue.svg";
import whatsapp from "../../../images/footer/whatsapp.png";
import Loadable from "@loadable/component";
import ModalTiny from "react-bootstrap/Modal"
import ValuationFormPage from "../../forms/valuation-form";
import BookAViewForm from "../../forms/book-a-viewing-form";
import { CloseIcon } from "../../Icons/Icons";
import './Menu.scss';

const GoogleTranslate = Loadable(() => import("../../GoogleTranslateWidget/GoogleTranslateWidget"));

const Menu = (props) => {
  // Scroll detection
  const scrolled = useHasScrolled();
  // Device type detection
  const { isCustomMax } = useDeviceMedia({ max: "991.98px" });
  const [renderComponent, setRenderComponent] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  // Fetch menus using GraphQL
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        headerMenus {
          id
          Parent_Label
          Parent_Link
          Parent_Menu_Link {
            id
            URL
          }
          Child_Menu {
            Child_Label
            Child_Link
            Child_Menu_Link {
              id
              URL
            }
            Child_Sub_Menu {
              Child_Sub_Menu_Label
              Child_Sub_Menu_Link
              Child_Sub_Menu_Menu_Link {
                id
                URL
              }
            }
          }
        }
        menuBurgers {
          id
          Parent_Label
          Parent_Link {
            id
            URL
          }
          Menu_Link {
            id
            URL
          }
          Sort
          Sub_Menus {
            Label
            Link {
              id
              URL
            }
          }
        }
      }
    }
  `);

  const menus = data.glstrapi.headerMenus;
  const burgerMenus = data.glstrapi.menuBurgers;
  const [scroll, setScroll] = useState(false);

  const locationLink = `${process.env.GATSBY_SITE_URL}${location.pathname}`;
  let whatsappLink = `https://wa.me/97144232006?text=Hello!%20I%20want%20to%20know%20more%20about%20${locationLink}`;

  if (location.search.includes("utm_source")) {
    whatsappLink = `https://wa.me/97144232006?text=*G*%20Hello!%20I%20want%20to%20know%20more%20about%20${locationLink}`;
  }

  useEffect(() => {
    const handleScroll = () => setScroll(window.scrollY > 60);

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleComponentRender);
    window.addEventListener("keypress", handleComponentRender);
    window.addEventListener("touchmove", handleComponentRender);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleComponentRender);
      window.removeEventListener("keypress", handleComponentRender);
      window.removeEventListener("touchmove", handleComponentRender);
    };
  }, [renderComponent]);

  const handleComponentRender = () => {
    if (!renderComponent) {
      setRenderComponent(true);
    }
  };
  const [activeTab, setActiveTab] = useState('requestCall'); 

  // Function to handle tab switch
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [modalValuationformOpen, setValuationformOpen] = React.useState(false);
  const openValuationformModal = () => {
    setValuationformOpen(true);
  }
  const closeValuationformModal = () => {
    setValuationformOpen(false);
  }

  // const logoSrc = scrolled ? LogoBlue : (isHomePage ? LogoWhite : LogoBlue);
  const logoSrc = scrolled 
    ? LogoBlue 
    : props?.TransparentHeader 
        ? LogoWhite 
        : (isHomePage ? LogoWhite : LogoBlue);

  return (
    <div className="header-menu-wrap">
      <Container>
      <Navbar expand="lg" className="header-navbar">
        <div className="header-menu-left">
          <Link to="/" className="navbar-brand">
            <img loading="lazy" className="logo" src={logoSrc} alt="Dacha logo" />
          </Link>
        </div>
        <Navbar.Collapse id="header-navbar-nav">
          <Nav className="m-auto desktop-menu" id="desktop-nav">
            {menus && menus.map((item, index) => (
              <MenuList key={index} item={item} />
            ))}
          </Nav>
        </Navbar.Collapse>
        <div className="header-menu-right">

        <div className="d-none d-lg-flex whatsapp-tablet">
          <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="whatsapp">
            <img loading="lazy" src={whatsapp} alt="whatsapp" />
          </a>
        </div>

        {/* Google Translate Widget Start */}
        <div className="ml-auto d-md-flex lang-btn google-translate-widget d-block d-md-block d-lg-block">
          {renderComponent && <GoogleTranslate />}
        </div>
        {/* Google Translate Widget End */}

        <div className="d-flex d-lg-none whatsapp-tablet">
          <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="whatsapp">
            <img loading="lazy" src={whatsapp} alt="whatsapp" />
          </a>
        </div>

        <div className="nav-right ml-auto d-none d-lg-flex widget-google-translate">
          <button className="btn btn-primary" onClick={openValuationformModal}>List Your Property</button>							
        </div>
        
        {isCustomMax && (
          <div className="burger-icon">
              <BurgerMenu
                menus={burgerMenus}
                isTransparent={scrolled}
              />
          </div> 
        )}
        </div>
        
    </Navbar>
    </Container>
      <ModalTiny show={modalValuationformOpen} onHide={closeValuationformModal} className="modal-form-wrapper list-prop-modal-wrap">      
        <ModalTiny.Header>  
          <div class="modal-close-btn" onClick={closeValuationformModal}><CloseIcon /></div>     
          <div className="modal-tab-navigation">
          <button 
            className={`modal-tab-button ${activeTab === 'requestCall' ? 'active' : ''}`} 
            onClick={() => handleTabChange('requestCall')}
          >
            Request a call back
          </button>
          <button 
            className={`modal-tab-button ${activeTab === 'bookValuation' ? 'active' : ''}`} 
            onClick={() => handleTabChange('bookValuation')}
          >
            Book a Valuation
          </button>
        </div>   
          <ModalTiny.Title>
          {activeTab === 'requestCall' ? <h4>Book an Appointment</h4> : <h4>Property Valuation</h4> }
            {/* <p className="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam nec risus nec risus convallis aliquet lipsum.</p> */}
          </ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body>
          {activeTab === 'requestCall' && 
            <BookAViewForm
            property_img={``}
            property_id={''}
            page_url={null}
            property_title={''}
            negotiator={''} />
          }
          {activeTab === 'bookValuation' && <ValuationFormPage />}
        </ModalTiny.Body>
      </ModalTiny>
    </div>
  );
};

export default Menu;