import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap"
import $ from "jquery";
import StaticContentContact from "../StaticContent/staticContentContact";
import StaticContactForm from "../forms/static-contact-form";
import HTMLReactParser from 'html-react-parser';
import Loadable from "@loadable/component"
const Breadcrumb = Loadable(() => import("../../components/Home/Breadcrumb/Breadcrumb"))
const About = Loadable(() => import("../../components/Home/AboutUs/About"))
const FeaturedProperties = Loadable(() => import("../../components/Home/Properties/FeaturedProperties"));
const Map = Loadable(() => import("../../components/map/google/single"))
const TeamSlider = Loadable(() => import("../../components/StaticComponent/OurTeam/OurTeam"))
const Peoples = Loadable(() => import("../../components/collections/Peoples"))
const OfficeContent = (props) => {
    // console.log(props,'officecontent')
    const [renderComponent, setRenderComponent] = useState(true);
    return (
        <React.Fragment>
            <div >

                {/* <Row className="static-content-contact container">
                    <Col className="static-contact-para">


                        {props.modules && props.modules.map((module, i) => (
                            module.Add_Static_Content && (
                                <StaticContentContact key={i} content={module.Add_Static_Content} />
                            )
                        ))}
                    </Col>

                    {props?.open_hours?.Opening_Hours && (
                        <Col xl={4} className="available-timings-contact-office text-sm">
                            {HTMLReactParser(props?.open_hours?.Opening_Hours.replace(/<br\s*\/?>/gi, ''))}
                        </Col>
                    )}

                </Row> */}


                {/* <StaticContactForm
                                property_img={``}
                                property_id={''}
                                page_url={null}
                                property_title={''}
                                negotiator={''}
                            /> */}
                {props.modules && props.modules.map((module, i) => {
                    return (
                        <div key={i}>


                            {module.Name === "Contact_Form" && <StaticContactForm
                                id={"contact-us-scroll"}
                                property_title={module.Title}
                                phone={props.page.Phone}
                                whatsapp={props.page.Whatsapp}
                                email={props.page.Email}
                                address={props.page.Address}
                            />}
                        </div>
                    );
                })}
            </div>

            {props.modules && props.modules.map((module, i) => {
                return (
                    <div key={i}>

                        {module.Latitude && module.Longtitude ?
                            <Map lat={module.Latitude ? parseFloat(module.Latitude) : ''} lng={module.Longtitude ? parseFloat(module.Longtitude) : ''} height={"600px"} zoom={14} /> : ''}

                        {renderComponent && module.Place && <FeaturedProperties place={module.Place} searchType={module.Search_Type} pagename={props.pagename} />}
                        {module.Teams_Intro_Content &&
                            <TeamSlider intro={module.Teams_Intro_Content} data={props?.peoplesData || module.Select_Peoples} /> // Use props.peoplesData if available

                        }
                        {/* {renderComponent && module.Add_Service &&
                            <About service={module.Add_Service} id={props.id} imagetransforms={props.imagetransforms} />

                        } */}

                    </div>
                );
            })}
        </React.Fragment>
    )
}

export default OfficeContent