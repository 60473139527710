import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'


import axios from "axios"
import * as qs from "query-string"

import $ from "jquery"

import "./assets/styles/Form.scss";

function SendFriendForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
      {
        element: "config",
        formname: "Send to Friend",
        error_text: "Highlighted fields are required | invalid",
        success_text: "Thank you for suggesting a property to your friend. We've sent the details to your friend.",
        email_temp_user: "send_to_friend_user",
        email_temp_admin: "send_to_friend_admin",
        email_server_func: "send_to_friend",
        event_tracking: "send_to_friend",
        page_url: "/property-wishlist"
      },
      {
        grpmd: "6",
        label: "Name",
        placeholder: "Your name *",
        name: "name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        class: "mb-4",
        step: "stepSix left",  
      },
      {
        grpmd: "6",
        label: "Email",
        placeholder: "Please add a valid email *",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        class: "mb-4",
        step: "stepSix right",
      },
      {
        grpmd: "6",
        label: "Friend's Name",
        placeholder: "Your Friend's name *",
        name: "friendname",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        class: "mb-4",
        step: "stepSix left",  
      },
      {
        grpmd: "6",
        label: "Friend's Email",
        placeholder: "Please add a valid email *",
        name: "friendemail",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        class: "mb-4",
        step: "stepSix right",  
      },
      {
        grpmd: "12",
        label: "Message",
        placeholder: "Leave your message",
        name: "message",
        element: "textarea",
        class: "mb-4",
        rows:"2"
      },
      {
        grpmd: "12",
        name: "SEND",
        type:"submit",
        element: "button",
        value: "submit",
        class: "btn-primary modal-btn",  
        labelClass: "text-xs-bold",
        step: "col-md-12 shareform-btns",  
      },
      // {
      //   text: 'By clicking Submit, you agree to our <a href="/terms-and-conditions"> Terms &amp; Conditions </a> and <a href="/privacy-policy"> Privacy Policy </a>',
      //   element: "html",
      //   class: "mb-0 mt-3 content_r-m terms"
      // },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  useEffect(() => {
    if (token !== '') {

      // lets send mail
      formvalues['email_temp_user'] = 'Send to a friend';
      formvalues['email_temp_admin'] = 'Send to a friend';
      window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/share_to_form`, {
        method: `POST`,
        mode: "no-cors",
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify(formvalues),
      })

      let mystrapidata = {
          "email" : formvalues.email,
          "form_name" : fields[0].formname,
          "message" : formvalues.message,
          "name" : formvalues.name,
          "telephone" : formvalues.telephone,
          "extra" : formvalues
      }

      if ( formvalues['property_id'] ) {
        mystrapidata.property_id = formvalues['property_id']
      }
      if ( formvalues['property_url'] ) {
        mystrapidata.property_url = process.env.GATSBY_SITE_URL+formvalues['property_url'];
      }

      var strapisettings = {
        "url": process.env.GATSBY_STRAPI_END_URL,
        "method": "POST",
        "timeout": 0,
        "headers": {
          "Authorization": "Bearer "+process.env.GATSBY_STRAPI_AT,
          "Content-Type": "application/json"
        },
        "data": JSON.stringify(mystrapidata),
      };

      $.ajax(strapisettings).done(function (response) {
        //console.log(response);
      });

      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': fields[0].formname
      });

      setShowerror(false);
      setThankyou(true);
      
      setTimeout(() => { 
        $(".alert-success").show().delay(4000).fadeOut(); 
    }, 500)
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

    }
  }, [token]);

  const handleonVerify = token => {
    //console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['property_id'] = props.subject.property_id ? props.subject.property_id : '';
      json['property_url'] = props.subject.page_url ? props.subject.page_url : '';
      json['property_title'] = props.subject.property_title ? props.subject.property_title : '';
      json['property_list'] = props.subject.properties ? props.subject.properties : '';
      json['property_img'] = props.subject.property_img ? props.subject.property_img : '';

      json['g-recaptcha-response'] = token;

      setFormvalues(json);
      //setToken("test");
      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      
    }
  };

  return (
    <div className="form stbform valuation-main share-to-friend">
    <div ref={myRef} />

    {showerror && <div className="alert-error">
      <p>{fields[0].error_text}</p>
    </div>}

    {showthankyou && <div className="alert-success">
      <p>{fields[0].success_text}</p>
    </div>}

    
      <Form name={fields[0].formname}   className="modal-form share-friend-form" method="post" noValidate validated={validated} onSubmit={handleSubmit} >
        <input type="hidden" name="form-name" value={fields[0].formname} />
        <input type="hidden" name="bot-field" />
        <div className="step-block">
        <Form.Row>
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  step={field.step}   
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  step={field.step}   
                  key={`${field.element}~${index}`}
                />
              );
            }
          })
        }
        </Form.Row>
            </div>
      </Form>
    </div>
  );
}


const SendFriendFormPage = (props) => (
  <SendFriendForm subject={props} />
)

export default SendFriendFormPage