import React, { useEffect, useState } from "react";
import HTMLReactParser from 'html-react-parser';
import $ from 'jquery'
import { navigate } from "@reach/router"
import axios from "axios";
import PlayVideo from "../Play/custom-video";
import { GetURL } from "../common/site/functions";
import { Button, Container, Form, Tabs, Tab } from 'react-bootstrap';
import Loadable from "@loadable/component"
import './Hero.scss';


const SearchInputBox = Loadable(() => import("../predictive-search/search-inputbox"))
const HeroSearch = Loadable(() => import("./HeroSearch/HeroSearch"))
const ImageTransform = Loadable(() => import("../common/ggfx-client/module/components/image-transform"))

const Hero = (props) => {

    const [renderComponent, setRenderComponent] = useState(false);

    useEffect(() => {
    
      window.addEventListener("mousemove", () => {
       
        if (renderComponent === false) {
          LoadVideoUrl()
          setRenderComponent(true)
        }
      })
      window.addEventListener("keypress", () => {
        if (renderComponent === false) {
          LoadVideoUrl()
          setRenderComponent(true)
        }
      })
      window.addEventListener("touchmove", () => {
        //scriptInsert()
        if (renderComponent === false) {
          LoadVideoUrl()
          setRenderComponent(true)
        }
      })



    $('.react-autosuggest__input').on('click', function () {
      if (props.handleChange) {
        props.handleChange("stop");
      }
    });
      
  }, [])

      const LoadVideoUrl = () => {
        if (typeof window !== 'undefined') {
          var video = $('#bannerVideo');
          var WindowWidth = $(window).width();
          if (WindowWidth > 572) {
            video.append("<source src='" + props.video + "' type='video/mp4' >");
          }
        }
      }

      const image_url = props.image.url

      let processedImages = JSON.stringify({});
      if (props.imagetransforms?.Full_Banner_Image_Transforms) {
        processedImages = props.imagetransforms.Full_Banner_Image_Transforms;
      } 

  return (
    <section className="hero-wrap">
      {props?.video ? (
       <div className="hero-video-wrap">
       <video id="bannerVideo" width="100%" height="100%" autoPlay muted playsInline loop className="video-banner d-none d-sm-block">

       </video>

       {props.image &&
         <ImageTransform imagesources={image_url} renderer="srcSet" imagename="articles.Full_Banner_Image.details" attr={{ alt: `${props.image.alternativeText} - Dacha`, class: 'd-block d-sm-none' }} imagetransformresult={processedImages} id={props.id} />
       }
       </div>
      ) : (
         <div className="hero-banner-wrap">
             {props.image &&
                <ImageTransform imagesources={image_url} renderer="srcSet" imagename="articles.Full_Banner_Image.details" attr={{ alt: `${props.image.alternativeText} - Dacha`}} imagetransformresult={processedImages} id={props.id} />
            }
         </div>
      )}
      <div className="hero-overlay">
        <div className="hero-overlay__content">
        <div className="hero-header">
            {props.Title &&
                <h1>{props?.Title}</h1>
            }
            {props.content &&
                <div className="content text-sm">
                  {HTMLReactParser(props?.content.replace(/<br\s*\/?>/gi, ''))}
                </div>
            }
        </div>
        <div className="hero-search-form">
            <HeroSearch type={props.search} />
        </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;