import React, { useState, useEffect, Fragment } from "react";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import axios from 'axios';
import { Container } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@StarberryUtils"

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import NewsLetter from "../components/Home/NewsLetter/NewsLetter";
import Breadcrumb from "../components/Home/Breadcrumb/Breadcrumb";
import OffPlanBanner from "../components/OffPlanDetails/OffPlanBanner/OffPlanBanner";
import DetailsMenu from "../components/OffPlanDetails/DetailsMenu/DetailsMenu";
import Opportunity from "../components/OffPlanDetails/ExclusiveOpportunity/Opportunity";
import Gallery from "../components/OffPlanDetails/Gallery/Gallery";
import KeyFeature from "../components/OffPlanDetails/KeyFeature/KeyFeature";
import Location from "../components/OffPlanDetails/Location/Location";
import Payment from "../components/OffPlanDetails/PaymentPlan/Payment";
import RegisterInterestForm from "../components/OffPlanDetails/RegisterInterestForm/RegisterInterestForm";
import SEO from "../components/seo"



import Development from "../components/OffPlanDetails/Development/Development";
import OffPlanStickyButton from "../components/PropertyDetails/StickyButton/OffPlanStickyButton";

import StaticContact from "../components/Home/ContactUs/StaticContact";


import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/scss/bootstrap.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/bootstrap-overrides.scss";
import "../scss/grunticon.scss";
import "../scss/global.scss";
import "../scss/custom.scss";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

const OffPlanPropertiesTemplate = (props) => {
  let [reviewCount, setReviewCount] = React.useState(0);
  let [averageRating, setAverageRating] = React.useState(0);
    const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };
    const strapiconfigs = {
        headers: {
            Authorization:
                `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        },
    }
     const urlReviws = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`;
      const getitems = async url => {
        try {
          const { data } = await axios.get(url,strapiconfigs)// could be from env files 
          setReviewCount(data.length);
          let sumOfScores = 0;
          data.map((review) => {
            const reviewStringValue = review.starRating;
            sumOfScores += wordToNumber[reviewStringValue];
          })
          setAverageRating(Math.round(sumOfScores / data.length * 10) / 10);
        } catch (error) {
          // cache it if fail/error;
    
        }
      }
    useEffect(() => {
        getitems(urlReviws);
      }, []);

  const property = props.data.glstrapi?.offPlanProperty;

  const properties = props.data.glstrapi?.offPlanProperties;
  const latestProperties = properties.filter(pro => pro.id !== property.id);
  const [state, setState] = React.useState({
    showMenu: false
  })
  const location = useLocation();
  const thePath = location.pathname
  const logoBlue = `${process.env.GATSBY_SITE_URL}/images/logo-blue.svg`;
  const handlerClick = () => {
    setState({ ...state, showMenu: !state.showMenu })
  }
  const meta_Image = property?.Gallery[0]?.url
  return (
    <div
      className={
        state.showMenu
          ? "off-details-page open-search-block" 
          : "off-details-page search-result-orange"
      }
    >
    <SEO image_url={meta_Image} title={property.Title}  description={property.Short_Description} location={props.location}/>
      <Header showMenu={state.showMenu} handlerClick={handlerClick} fixed={true}/>
      {averageRating!==0 && reviewCount!==0 && (
      <Helmet>
                <script type="application/ld+json">{`{
                   "@context" : "http://schema.org",
                    "@type" : "Product",
                    "name" : "${property.Meta_Title}| Dacha Real Estate",
                    "image": "${property.Gallery[10]?.url ? property.Gallery[10]?.url : (property.Gallery[1]?.url ? property.Gallery[1]?.url : property.Gallery[0]?.url)}",
                    "description" : "${property.Short_Description}",
                    "brand" : {
                          "@type" : "Organization",
                          "name" : "Dacha Real Estate",
                          "logo" : "${logoBlue}"
                      },
                    "aggregateRating": {
                          "@type": "AggregateRating",
                          "ratingValue": "${averageRating}",
                          "reviewCount": "${reviewCount}"
                      },

                    "offers": {
                          "@type": "Offer",
                          "url": "${process.env.GATSBY_SITE_URL}${thePath}",
                          "priceCurrency": "AED",
                          "price": "${property.Price}",
                          "availability": "https://schema.org/InStock"
                        }
                      }
          `}</script>
                
        </Helmet>
      )}
      
      <Breadcrumb data={''} tag={`detail-page`} name={property.Title} category={`Off Plan Properties`} />
      <OffPlanBanner data={property} />
      <DetailsMenu data={property} />
      <Opportunity data={property} />
      {property.Gallery &&
        <Gallery data={property} />
      }
      <KeyFeature data={property} />
      {property.Latitude &&
        <Location data={property} />
      }
      {property.Add_Payment_Plan &&
        <Payment data={property.Add_Payment_Plan} />
      }
      <RegisterInterestForm />

      {latestProperties.length > 0 &&
      <div className="latest-development-wrapper sec-pdy-120">
        <Container>
        <Development data={latestProperties[0]} type={`latest`} />
        </Container>
        </div>
      }



      <StaticContact />
      {/* <NewsLetter /> */}
      <div className="footer-section property-details-footer">
        <Footer />
      </div>
      <OffPlanStickyButton data={property}/>
    </div>
  )
}


export default OffPlanPropertiesTemplate;

export const pageQuery = graphql`
query OffPlanQuery($id: ID!){
  glstrapi {
    offPlanProperty(id: $id, publicationState: LIVE) {
      Title
      URL 
      Completion_Date
      Details_Page_Description
      Details_Page_Intro
      Developer
      Developer_Type 
      Key_Features
      Latitude
      Location
      Longtitude
      Meta_Description
      Meta_Title
      Price
      Publish
      Short_Description 
      Video_URL
      id
      Gallery {
        alternativeText
        url
      }
      Upload_Brochure {
        url
      }
      Banner_Image {
        url
        alternativeText
      }
      Add_Payment_Plan {
        Title
        Description
        Value
      } 
      Tile_Image {
        alternativeText
        url
      }  
      imagetransforms
    }
    offPlanProperties(sort: "createdAt:desc", limit: 2, where: {Publish: true}) {
      Title
      URL
      Price
      id
      Location
      Developer
      Developer_Type
      Short_Description
      Tile_Image {
        alternativeText
        url
      }
      imagetransforms
    }
  }
}
`;